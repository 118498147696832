import React from "react"
import { Transition } from "react-transition-group"

import { CardFrontWWCD } from "../svg"

export default props => {
    return (
        <div
            className="card-front"
            css={css`
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: #67430e;
                display: flex;
                align-items: center;
                justify-content: center;
            `}
            onClick={props.onClick}
        >
            <CardFrontWWCD />
        </div>
    )
}
