import React from "react"
import { hot } from "react-hot-loader"
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

import GameScreen from "./GameScreen"
import TestBed from "./testBed/TestBed"

import "./App.scss"

class Main extends React.Component {
    render() {
        const props = this.props
        return (
            <div
                css={css`
                    perspective: 400;
                    perspective-origin: 50% 50%;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <GameScreen />
            </div>
        )
    }
}

class App extends React.Component {
    render() {
        return (
            <Router>
                <Route path="/" exact component={Main} />
                <Route path="/test" exact component={TestBed} />
            </Router>
        )
    }
}

export default hot(module)(App)
