import React from "react"

import { TweenMax, TimelineMax, Elastic } from "gsap"
import { SpotLightSVG } from "./svg"

export default class SpotLight extends React.Component {
    constructor(props) {
        super(props)

        this.el = React.createRef()
        this.innerEl = React.createRef()
    }
    componentDidMount() {
        this.updateTrans()
    }
    componentDidUpdate(prevProps) {
        this.updateTrans()
    }
    updateTrans() {
        const props = this.props

        TweenMax.set(this.innerEl.current, {
            scaleX: props.coneLength / 100,
            scaleY: props.coneWidth / 100,
            rotation: props.angle,
            opacity: props.brightness,
        })
        if (!!props.pointAt) this.updatePointAt()
    }
    updatePointAt() {
        const props = this.props

        let angle
        let x = 0
        let y = 0

        const pointAtEl = document.querySelector(props.pointAt)
        if (!pointAtEl) return
        const myBounds = this.el.current.getBoundingClientRect()
        const theirBounds = pointAtEl.getBoundingClientRect()
        const theirCenter = {
            left: theirBounds.left + theirBounds.width / 2,
            top: theirBounds.top + theirBounds.height / 2,
        }
        const theirLocal = {
            x: theirCenter.left - myBounds.left,
            y: theirCenter.top - myBounds.top,
        }
        if (props.pointAtOptions) {
            if (props.pointAtOptions.scootX) {
                x = theirLocal.x * props.pointAtOptions.scootX
            }
        }

        const pointRads = Math.atan2(theirLocal.y - y, theirLocal.x - x)
        angle = pointRads * (180 / Math.PI)

        TweenMax.set(this.innerEl.current, {
            x,
            y,
            rotation: angle,
        })
    }
    render() {
        const props = this.props
        const {
            coneWidth,
            coneLength,
            angle,
            pointAt,
            pointAtOptions,
            ...divProps
        } = props
        return (
            <div {...divProps} ref={this.el}>
                <div
                    css={css`
                        mix-blend-mode: color-dodge;
                        transform-style: initial;
                    `}
                    ref={this.innerEl}
                >
                    <SpotLightSVG
                        css={css`
                            position: absolute;
                            left: 0px;
                            top: -50px;
                            mix-blend-mode: color-dodge;
                            transform-style: initial;
                        `}
                        width="100"
                        height="100"
                    />
                </div>
            </div>
        )
    }
}
