import React from "react"
import { TweenLite, TweenMax, Elastic } from "gsap"

function getOffset(el) {
    var _x = 0
    var _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft
        _y += el.offsetTop - el.scrollTop
        el = el.offsetParent
    }
    return { top: _y, left: _x }
}

function getPagePosFromEvent(ev) {
    let x, y
    if (ev.touches) {
        x = ev.touches[0].pageX
        y = ev.touches[0].pageY
    } else {
        let x = ev.clientX
        let y = ev.clientY
    }
    return { x, y }
}

export default class PressTilt extends React.Component {
    static defaultProps = {
        zMod: 1,
        rxMod: 1,
        ryMod: 1,
    }

    constructor(props) {
        super(props)
        console.log("props", props)
        this.el = React.createRef()
        this.innerEl = React.createRef()
        this.tl = null
    }
    kill() {
        if (this.tl) {
            this.tl.kill()
            this.tl = null
        }
    }
    onPress(ev) {
        this.updateTilt(ev)
    }
    onUp(ev) {
        this.kill()
        this.tl = TweenLite.to(this.el.current, 0.5, {
            z: 0,
            rotationX: 0,
            rotationY: 0,
            ease: Elastic.easeOut.config(1, 0.5),
        })
    }
    getTiltFromPos(pos) {
        const props = this.props

        const width = this.el.current.clientWidth
        const height = this.el.current.clientHeight

        const myPagePos = getOffset(this.el.current)

        let localX = pos.x - myPagePos.left
        let localY = pos.y - myPagePos.top

        localX -= width / 2
        localY -= height / 2

        let distFromCenter = Math.sqrt(localX * localX + localY * localY)

        let rotationX = ((localY * -0.15) / (width / 100)) * props.rxMod
        let rotationY = ((localX * 0.15) / (height / 100)) * props.ryMod

        // let distPer = distFromCenter / 100
        // distPer = distPer * distPer
        // distPer = 1 - distPer
        // let z = distPer * -25
        let z = -25 * props.zMod

        return {
            z,
            rotationX,
            rotationY,
        }
    }
    updateTilt(fromEv) {
        const pos = getPagePosFromEvent(fromEv)
        const { z, rotationX, rotationY } = this.getTiltFromPos(pos)
        TweenLite.set(this.el.current, {
            z,
            rotationX,
            rotationY,
        })
    }
    onMove(ev) {
        this.updateTilt(ev)
    }
    render() {
        const props = this.props
        return (
            <div
                ref={this.el}
                css={css`
                    position: relative;
                    transform-style: preserve-3d;
                `}
                {...props}
                onTouchStart={this.onPress.bind(this)}
                onTouchCancel={this.onUp.bind(this)}
                onTouchEnd={this.onUp.bind(this)}
                onTouchMove={this.onMove.bind(this)}
            />
        )
    }
}
