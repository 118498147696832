import React from "react"
import { readFileSync } from "fs"

const tagName2JSName = {
    "xmlns:xlink": "xmlnsXlink",
}

function makeSVG(svgStr) {
    const svgDoc = new DOMParser().parseFromString(svgStr, "application/xml")
    const defaultProps = {}
    Array.from(svgDoc.rootElement.attributes).reduce((obj, attr) => {
        const name = tagName2JSName[attr.name] || attr.name
        defaultProps[name] = attr.value
    }, {})

    const innerSVGStr = svgDoc.rootElement.innerHTML
    return props => (
        <svg
            {...defaultProps}
            {...props}
            dangerouslySetInnerHTML={{ __html: innerSVGStr }}
        />
    )
}

export const CardFrontWWCD = makeSVG(
    readFileSync(__dirname + "/../assets/CardFront-WWCD.svg")
)
export const Scoreboard = makeSVG(
    readFileSync(__dirname + "/../assets/scoreboard.svg")
)
export const SpotLightSVG = makeSVG(
    readFileSync(__dirname + "/../assets/spot-light.svg")
)
export const GameInfoBackingSVG = makeSVG(
    readFileSync(__dirname + "/../assets/game-info-backing.svg")
)
export const MiniFansSVG = makeSVG(
    readFileSync(__dirname + "/../assets/mini-fans.svg")
)
export const FanSeatSVG = makeSVG(
    readFileSync(__dirname + "/../assets/fan-seat.svg")
)
export const WocIconSVG = makeSVG(
    readFileSync(__dirname + "/../assets/woc-icon.svg")
)
export const TinyCoinSVG = makeSVG(
    readFileSync(__dirname + "/../assets/tiny-coin.svg")
)
export const FanSVG = makeSVG(readFileSync(__dirname + "/../assets/fan.svg"))
