import React from "react"

import Option from "../card/Option"
import TiltOption from "./TiltOption"
import PressTilt from "../PressTilt"
import GSWLogoURL from "../../assets/GSW-Logo.png"
import BOSLogoURL from "../../assets/BOS-Logo.png"

const TestPressTilt = props => {
    return (
        <PressTilt zMod={0.5} rxMod={1} ryMod={1}>
            <TiltOption
                background="#0065AD"
                logoURL={GSWLogoURL}
                team="GSW"
                winAmount="300"
                selected={"none"}
            />
        </PressTilt>
    )
}

export default TestPressTilt
