import React from 'react'

const SvgComponent = props => (
  <svg width={8} height={13} viewBox="0 0 8 13" fill="none" {...props}>
    <path
      d="M0.599854 5.54708L2.78502 7.37067L1.49612 12.0844L7.19985 7.35315L5.01468 5.54726L6.36931 0.779358L0.599854 5.54708Z"
      fill="#165D34"
    />
  </svg>
)

export default SvgComponent
