import React from "react"
import { TinyCoinSVG } from "../svg"

const Coins = props => {
  return (
    <span
      css={css`
        position: relative;
        width: 16px;
        height: 16px;
        margin: 0 5px;
      `}
    >
      <TinyCoinSVG
        css={css`
          position: absolute;
          left: -2px;
          top: -2px;
        `}
      />
      <TinyCoinSVG
        css={css`
          position: absolute;
          left: 5px;
          top: 1px;
        `}
      />
      <TinyCoinSVG
        css={css`
          position: absolute;
          left: 1px;
          top: 4px;
        `}
      />
    </span>
  )
}

export default props => {
  return (
    <div
      className="coin-purse"
      css={css`
        position: relative;
        display: flex;
        justify-content: center;
        margin: 16px 0;
      `}
    >
      <div
        css={css`
          width: 133px;
          height: 31px;
          border: 2px solid rgba(189, 189, 189, 0.3);
          box-sizing: border-box;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Coins />
        <span
          css={css`
            font-weight: bold;
            font-size: 14px;
            color: #bdbdbd;
            margin: 0 5px;
          `}
        >
          8
        </span>
        <span
          css={css`
            font-size: 10px;
            color: #828282;
          `}
        >
          PICKS LEFT
        </span>
      </div>
    </div>
  )
}
