import React from "react"

import Card from "../card/Card"
import Coin from "../Coin"
import TestCoin from "./TestCoin"
import TestPressTilt from "./TestPressTilt"

const comps = [
    { label: "Coin Flipper", comp: Coin },
    { label: "Press Tilt", comp: TestPressTilt },
    { label: "Card", comp: Card },
]

export default class TestBed extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            testComp: null,
        }
    }

    onSelectChange(ev) {
        const index = parseInt(ev.currentTarget.value)
        const testComp = comps[index].comp
        this.setState({
            testComp,
        })
    }

    render() {
        let comp
        if (this.state.testComp) {
            comp = React.createElement(this.state.testComp)
        }

        return (
            <div
                css={css`
                    perspective: 400;
                    perspective-origin: 50% 50%;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                    `}
                >
                    <select
                        value=""
                        css={css`
                            background: #fff;
                            width: 100%;
                            font-size: 20px;
                            margin: 5px;
                        `}
                        onChange={this.onSelectChange.bind(this)}
                    >
                        <option disabled value="">
                            Pick a Component
                        </option>
                        {comps.map((comp, index) => {
                            return (
                                <option key={comp.label} value={index}>
                                    {comp.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
                {comp}
            </div>
        )
    }
}
