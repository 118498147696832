import React from "react"

import BoltSVG from "../../assets/bolt"
import { relative } from "path"

export default props => {
    return (
        <div
            className="group-3d"
            css={css`
                width: 154px;
                height: 206px;
                position: relative;
                /* outline: 1px solid red; */
            `}
        >
            <div
                className="backing"
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    transform: translateZ(-25px) scale(1.07);

                    background: ${props.background || "blue"};
                    border-radius: 5px;
                `}
            />
            <div
                className="content group-3d"
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    transform: translateZ(0);

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <div
                    className="fave"
                    css={css`
                        height: 13px;
                        margin-bottom: 10px;
                    `}
                />
                <div
                    pose={props.selected ? "selected" : "init"}
                    className="logo group-3d"
                >
                    <div
                        css={css`
                            position: absolute;
                            left: 0;
                            top: 1px;
                            color: hsl(205, 100%, 25%);
                            transform: translateZ(-24px) scale(1.02);
                            filter: brightness(0) blur(1px);
                            opacity: 0.3;
                        `}
                    >
                        <img
                            src={props.logoURL}
                            style={{
                                objectFit: "cover",
                                width: "63px",
                                height: "63px",
                            }}
                        />
                    </div>
                    <div>
                        <img
                            src={props.logoURL}
                            style={{
                                objectFit: "cover",
                                width: "63px",
                                height: "63px",
                            }}
                        />
                    </div>
                </div>
                <div
                    className="team-alias group-3d"
                    css={css`
                        position: relative;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 26px;
                    `}
                >
                    {[...Array(4)].map((el, index, ar) => {
                        const maxZ = -20
                        const per = (index + 1) / ar.length
                        const z = per * maxZ
                        return (
                            <div
                                key={index}
                                css={css`
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    color: hsl(205, 100%, 25%);
                                    transform: translateZ(${z}px) scale(1.05);
                                `}
                            >
                                {props.team}
                            </div>
                        )
                    })}
                    <div>{props.team}</div>
                </div>
                <div
                    css={css`
                        font-weight: 500;
                        font-size: 9px;
                        text-align: center;
                        letter-spacing: 0.26em;

                        color: #ffffff;

                        mix-blend-mode: overlay;
                        margin-bottom: 5px;
                    `}
                >
                    WIN
                </div>
                <div
                    css={css`
                        background: #00e7ae;
                        border-radius: 10px;
                        font-weight: bold;
                        font-size: 14px;
                        color: #165d34;
                        width: 61px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: translateZ(5px);
                    `}
                >
                    <BoltSVG
                        css={css`
                            margin-right: 5px;
                        `}
                    />
                    <span
                        css={css`
                            margin-right: 6px;
                        `}
                    >
                        {props.winAmount}
                    </span>
                </div>
            </div>
        </div>
    )
}
