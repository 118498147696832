import React from "react"
import styled from "styled-components"

import CARD from "../assets/Template-CARD.png"

const name2url = {
    CARD
}

const TemplateDiv = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${props=>name2url[props.name]});
    background-size: 100%;
    pointer-events: none;
    outline: 5px solid red;
    opacity: .5;
`

export default props => {
    return <TemplateDiv {...props} />
}
