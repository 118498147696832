import React from "react"
import { MiniFansSVG, FanSeatSVG, FanSVG } from "../svg"

const FanSeat = props => {
    return (
        <FanSeatSVG
            css={css`
                flex: initial;
            `}
        />
    )
}

export default props => {
    return (
        <div
            css={css`
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 134px;
            `}
        >
            <MiniFansSVG />
            <div
                css={css`
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background: #242424;
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        bottom: 32px;
                        width: 100%;
                        height: 45px;
                        background: #1d1d1d;
                    `}
                />
                <div
                    css={css`
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 32px;
                        background: #454545;
                    `}
                />
                <div
                    css={css`
                        position: absolute;
                        bottom: 16px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <FanSeat />
                    <FanSeat />
                    <FanSeat />
                    <FanSeat />
                    <FanSeat />
                </div>
                <div
                    className="fan-you"
                    css={css`
                        position: absolute;
                        bottom: 7px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <FanSVG />
                </div>
            </div>
        </div>
    )
}
