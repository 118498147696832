import React from "react"
import posed from "react-pose"

import BoltSVG from "../../assets/bolt"
import { WocIconSVG } from "../svg"
import PressTilt from "../PressTilt"

const MainPosed = posed.div({
    none: {
        filter: "grayscale(0%) brightness(100%)",
        z: 0,
    },
    selected: {
        filter: "grayscale(0%) brightness(100%)",
        z: 0,
    },
    unselected: {
        filter: "grayscale(50%) brightness(75%)",
        z: -35,
    },
})

export default props => {
    return (
        <div
            css={css`
                position: relative;
                /* outline: 1px solid red; */
            `}
        >
            <MainPosed
                {...props}
                pose={props.selected || "none"}
                css={css`
                    width: 154px;
                    height: 206px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    background: ${props.background || "blue"};
                    border-radius: 5px;
                `}
            >
                <div
                    css={css`
                        height: 13px;
                        margin-bottom: 10px;
                    `}
                />
                <div
                    pose={props.selected ? "selected" : "init"}
                    className="logo"
                >
                    <img
                        src={props.logoURL}
                        style={{
                            objectFit: "cover",
                            width: "63px",
                            height: "63px",
                        }}
                    />
                </div>
                <div
                    style={{
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textAlign: "center",
                        color: "#fff",
                        marginBottom: "26px",
                    }}
                >
                    {props.team}
                </div>
                <div
                    css={css`
                        font-weight: 500;
                        font-size: 9px;
                        text-align: center;
                        letter-spacing: 0.26em;

                        color: #ffffff;

                        mix-blend-mode: overlay;
                        margin-bottom: 5px;
                    `}
                >
                    WIN
                </div>
                <div
                    css={css`
                        background: #00e7ae;
                        border-radius: 10px;
                        font-weight: bold;
                        font-size: 14px;
                        color: #165d34;
                        width: 61px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <BoltSVG
                        css={css`
                            margin-right: 5px;
                        `}
                    />
                    <span
                        css={css`
                            margin-right: 6px;
                        `}
                    >
                        {props.winAmount}
                    </span>
                </div>
            </MainPosed>

            <div
                className="odds"
                css={css`
                    position: absolute;
                    bottom: -47px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    color: #f2c94c;
                    text-align: center;
                    width: 100%;
                `}
            >
                <WocIconSVG
                    css={css`
                        position: relative;
                        top: -1px;
                        margin-right: 3px;
                    `}
                />
                <span>72%</span>
            </div>
        </div>
    )
}
