import React from "react"

import ScoreArea from "./scoreArea/ScoreArea"
import Jumbotron from "./jumbotron/Jumbotron"
import CardArea from "./cardArea/CardArea"
import PeriodNav from "./periodNav/PeriodNav"
import FanArea from "./fanArea/FanArea"

export default props => {
    return (
        <div
            css={css`
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
            `}
        >
            <ScoreArea />
            <Jumbotron />
            <CardArea />
            <PeriodNav />
            <FanArea />
        </div>
    )
}
