import React from "react"

import NavItem from "./NavItem"
import SpotLight from "../SpotLight";


export default props => {
    const [ activeIndex, setActive ] = React.useState(0)

    return <div css={css`
        position: relative;
        height: 100px;
        background: #242424;
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
    `}>
        <SpotLight css={`
            position: absolute;
            left: 50%;
            top: 100px;
        `}
            coneWidth={130}
            coneLength={200}
            pointAt="#nav-spot-dest"
            pointAtOptions={{
                scootX: .7,
            }}
            brightness={.2}
        />

        <NavItem label="G" active={activeIndex===0}  onClick={ev=>setActive(0)}/>
        <NavItem label="1Q" active={activeIndex===1}  onClick={ev=>setActive(1)} />
        <NavItem label="2Q" active={activeIndex===2}  onClick={ev=>setActive(2)} />
        <NavItem label="3Q" active={activeIndex===3}  onClick={ev=>setActive(3)} />
        <NavItem label="4Q" active={activeIndex===4}  onClick={ev=>setActive(4)} />
    </div>
}