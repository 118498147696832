import React from "react"

import Coin from "../Coin"

const TestCoin = props => {
    return (
        <div
            css={css`
                width: 100%;
                height: 100%;
            `}
        >
            <Coin />
        </div>
    )
}

export default TestCoin
