import React from "react"

import Option from "./Option"
import GSWLogoURL from "../../assets/GSW-Logo.png"
import BOSLogoURL from "../../assets/BOS-Logo.png"

export default props => {
    const [selectedIndex, setIndex] = React.useState(-1)

    const anySelected = selectedIndex >= 0

    return (
        <div
            className="card-main"
            css={css`
                position: relative;
                width: 93%;
                height: 100%;
                margin: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                perspective: 400;
                perspective-origin: 50% 50%;
            `}
        >
            <Option
                background="#0065AD"
                logoURL={GSWLogoURL}
                team="GSW"
                winAmount="300"
                selected={
                    anySelected
                        ? selectedIndex === 0
                            ? "selected"
                            : "unselected"
                        : "none"
                }
                onClick={ev => setIndex(0)}
            />
            <Option
                background="#006636"
                logoURL={BOSLogoURL}
                team="BOS"
                winAmount="500"
                selected={
                    anySelected
                        ? selectedIndex === 1
                            ? "selected"
                            : "unselected"
                        : "none"
                }
                onClick={ev => setIndex(1)}
            />
        </div>
    )
}
