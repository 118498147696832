import React from "react"
import posed from "react-pose"

import { Scoreboard, GameInfoBackingSVG } from "../svg"
import GSWLogoURL from "../../assets/GSW-Logo.png"
import BOSLogoURL from "../../assets/BOS-Logo.png"
import SpotLight from "../SpotLight"

const Team = props => {
    return (
        <div
            className="team"
            css={css`
                position: absolute;
                top: 0;
                width: 83px;
                height: 83px;
            `}
            {...props}
        >
            <SpotLight
                css={`
                    position: absolute;
                    left: ${props.spotLightLeft}px;
                    top: 136px;
                `}
                coneWidth={90}
                coneLength={200}
                angle={props.spotLightAngle}
                brightness={0.2}
            />
            <img
                src={props.logoUrl}
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    width: 63px;
                    height: 63px;
                    margin: 10px;
                `}
            />
        </div>
    )
}

export default props => {
    return (
        <div
            className="score-area"
            css={css`
                position: relative;
                height: 94px;
                background: #111111;
                display: flex;
                align-items: center;
                justify-content: center;
            `}
        >
            <Team
                logoUrl={GSWLogoURL}
                spotLightLeft={100}
                spotLightAngle={-90 - 32}
                css={css`
                    left: 0;
                `}
            />
            <Scoreboard />
            <Team
                logoUrl={BOSLogoURL}
                spotLightLeft={-20}
                spotLightAngle={-90 + 32}
                css={css`
                    right: 0;
                `}
            />
            <div
                css={css`
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 0;
                    display: flex;
                    justify-content: center;
                    z-index: 100;
                    pointer-events: none;
                `}
            >
                <div>
                    <GameInfoBackingSVG />
                </div>
            </div>
        </div>
    )
}
