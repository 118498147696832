import React from "react"
import { keyframes } from "styled-components"
import { TweenLite, TimelineLite, Power1, Bounce, Linear } from "gsap"

const fadeIn = keyframes`
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(360deg);
  }
`

function createBounceTL(el, dur, height) {
    const tl = new TimelineLite()
    tl.to(el, dur * 0.5, {
        z: height,
        ease: Power1.easeOut,
    })
    tl.to(el, dur * 0.5, {
        z: 0,
        ease: Power1.easeIn,
    })
    return tl
}

export default class Coin extends React.Component {
    constructor(props) {
        super(props)

        this.el = React.createRef()
        this.innerEl = React.createRef()
    }
    onClick() {
        let tl = new TimelineLite()
        const dur = 0.6
        const height = 100
        tl.add(createBounceTL(this.innerEl.current, dur, height))
        tl.add(createBounceTL(this.innerEl.current, dur * 0.5, height * 0.25))
        tl.add(createBounceTL(this.innerEl.current, dur * 0.25, height * 0.1))
        tl.to(
            this.innerEl.current,
            dur,
            {
                rotationX: 360,
                ease: Power1.easeOut,
            },
            0
        )
        tl.to(
            this.el.current,
            dur * 1.5,
            {
                x: -30,
                y: -30,
                ease: Power1.easeOut,
            },
            0
        )
        tl.from(
            this.el.current,
            dur * 0.25,
            {
                scale: 0,
                ease: Power1.easeOut,
            },
            0
        )
    }

    render() {
        return (
            <div
                ref={this.el}
                css={css`
                    position: relative;
                    width: 50px;
                    height: 50px;
                    transform-style: preserve-3d;
                `}
            >
                <div
                    ref={this.innerEl}
                    onClick={this.onClick.bind(this)}
                    css={css`
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        transform-style: preserve-3d;
                    `}
                >
                    <div
                        className="face"
                        css={css`
                            position: absolute;
                            left: 2px;
                            top: 0px;
                            width: 45px;
                            height: 45px;
                            transform: translateZ(2px);
                        `}
                    >
                        <svg
                            css={css`
                                position: absolute;
                                left: 0;
                                top: 0;
                            `}
                            width={45}
                            height={45}
                            viewBox="0 0 45 45"
                            fill="none"
                        >
                            <circle
                                cx={22.3616}
                                cy={22.4085}
                                r={22.3616}
                                fill="#F2C94C"
                            />
                            <circle
                                cx={22.3618}
                                cy={22.4086}
                                r={19.3801}
                                fill="#F2C94C"
                            />
                            <circle
                                cx={22.3618}
                                cy={22.4086}
                                r={19.3801}
                                fill="url(#paint0_linear)"
                            />
                            <circle
                                cx={22.362}
                                cy={22.4086}
                                r={17.8893}
                                fill="#F2C94C"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1={22.3618}
                                    y1={3.0285}
                                    x2={22}
                                    y2={36.5}
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#B69739" />
                                    <stop offset={1} stopColor="#F2C94C" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <div
                            css={css`
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            `}
                        >
                            <span
                                css={css`
                                    font-weight: bold;
                                    font-size: 13px;
                                    letter-spacing: -0.03em;

                                    color: #91792e;
                                `}
                            >
                                PICK
                            </span>
                        </div>
                    </div>
                    <Circle z={0} top={2} />
                    <Circle z={-2} top={4} />
                </div>
            </div>
        )
    }
}

const Circle = props => {
    return (
        <div
            className="back"
            css={css`
                position: absolute;
                left: 2px;
                top: ${props.top}px;
                width: 45px;
                height: 45px;
                transform: translateZ(${props.z}px);
            `}
        >
            <svg
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                `}
                width={45}
                height={45}
                viewBox="0 0 45 45"
                fill="none"
            >
                <circle cx={22.3616} cy={22.4085} r={22.3616} fill="#91792e" />
            </svg>
        </div>
    )
}
