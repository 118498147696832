import React from "react"

import Card from "../card/Card"
import CoinPurse from "./CoinPurse"

const CardPicker = props => {
  return (
    <div
      className="card-picker"
      css={css`
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        flex: 1 1 auto;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin: 0 25px;
        `}
      >
        {props.cards.map((card, index) => (
          <div
            key={index}
            css={css`
              scroll-snap-align: center;
              margin: 0 6px;
              position: relative;

              // this make scrolling much faster on iOS
              transform: translate3d(0, 0, 10px);
            `}
          >
            <Card />
          </div>
        ))}
      </div>
    </div>
  )
}

export default props => {
  return (
    <div
      className="card-area"
      css={css`
        height: 410px;
        background: #181818;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        className="remaining"
        css={css`
          font-size: 10px;
          text-align: center;
          letter-spacing: 0.1em;
          color: #bdbdbd;
          opacity: 0.5;
          margin: 10px 0;
        `}
      >
        10:55 LEFT
      </div>
      <CardPicker cards={[1, 2, 3]} />
      <CoinPurse />
    </div>
  )
}
