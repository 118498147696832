import React from "react"
import posed from "react-pose";



const PosedDiv = posed.div({})
const PosedLabel = posed.div({
    init: {
        opacity: .3
    },
    active: {
        opacity: 1
    }
})

export default props => {
    return <PosedDiv
        {...props}

        id={props.active ? "nav-spot-dest":null}

        css={css`
            width: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 0;
        `}
        pose={props.active ? "active":"init"}
    >
        <PosedLabel css={css`
            font-weight: bold;
            font-size: 18px;
        `}>{props.label}</PosedLabel>
        <div css={css`
            margin-top: 10px;
        `}>
            <BetLine />
            <BetLine />
            <BetLine />
        </div>
    </PosedDiv>
}


const BetLine = props => {
    return <div css={css`
        width: 44px;
        height: 5px;
        background: #f2c94c;
        margin-bottom: 5px;
    `}></div>
}

// active bg - #4F4F4F
// winning bg - #00E7AE
// mid bg - #F2994A
// losing bg - #EB5757