import React from "react"

export default props => {
    return (
        <div
            css={css`
                position: relative;
                height: 188px;
                background: #000;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
            `}
        >
            <div
                css={css`
                    position: relative;
                    width: 382px;
                    height: 178px;
                    background: linear-gradient(#12171e, #255595);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <div
                    css={css`
                        font-weight: 600;
                        font-size: 32px;
                        text-align: center;
                        letter-spacing: -0.03em;
                        color: #f2f2f2;
                    `}
                >
                    Let’s make your
                    <br />
                    game picks!
                </div>
            </div>
        </div>
    )
}
