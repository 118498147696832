import React from "react"
import { TweenMax, TimelineMax, Elastic } from "gsap"
import { Transition } from "react-transition-group"

import Template from "../Template"

import CardFront from "./CardFront"
import CardMain from "./CardMain"

export default class Card extends React.PureComponent {
    static defaultProps = {}
    constructor(props) {
        super(props)

        this.state = {
            showFront: true,
        }

        this.el = React.createRef()
    }
    q(sel) {
        return this.el.current.querySelectorAll(sel)
    }
    componentDidMount(prevProps) {
        TweenMax.from(this.q("#Fork"), 1, {
            x: -150,
            rotation: -90,
            transformOrigin: "50% 50%",
            ease: Elastic.easeOut.config(1, 0.5),
        })
        TweenMax.from(
            this.q("#Knife"),
            1,
            {
                x: 150,
                rotation: 250,
                transformOrigin: "50% 50%",
                ease: Elastic.easeOut.config(1, 0.65),
            },
            0.25
        )
    }
    render() {
        return (
            <div
                className="card"
                css={css`
                    position: relative;
                    display: inline-block;
                    width: 352px;
                    height: 242px;

                    margin-bottom: 45px;

                    border: 4px solid #ffffff;
                    background: #000000;
                    box-sizing: border-box;
                    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;

                    // this make scrolling much faster on iOS
                    transform: translate3d(0, 0, 0px);

                    /* outline: 1px solid red; */
                `}
                ref={this.el}
            >
                <Transition
                    mountOnEnter
                    unmountOnExit
                    in={!this.state.showFront}
                    timeout={10}
                >
                    {state => <CardMain transState={state} />}
                </Transition>

                <Transition
                    mountOnEnter
                    unmountOnExit
                    in={this.state.showFront}
                    timeout={10}
                >
                    {state => (
                        <CardFront
                            transState={state}
                            onClick={ev => this.setState({ showFront: false })}
                        />
                    )}
                </Transition>
                <div
                    className="topper"
                    css={css`
                        position: absolute;
                        left: -4px;
                        top: -4px;
                        width: 100%;
                        height: 100%;
                        border: 4px solid #fff;
                        border-radius: 10px;
                        pointer-events: none;
                    `}
                />
            </div>
        )
    }
}
